import React from 'react'

const Lead = ({ leadHeading, leadText }) => {
  return (
    <div className="container py-3 md:py-15">
      <div className="-ml-8">
        <div className="w-full pl-8 pb-3 md:pb-8">
          {leadHeading && (
            <div className="w-full ">
              <h2 className="text-xl md:text-4xl font-semibold leading-none mb-8 md:mb-12">
                {leadHeading}
              </h2>
              <div className="dash mb-5 md:mb-0"></div>
            </div>
          )}
        </div>
        <div className="w-full pl-8">
          <div className="w-full xl:w-11/12">
            {leadText.html && (
              <div
                className="text-xs md:text-lg"
                dangerouslySetInnerHTML={{
                  __html: leadText.html,
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lead
