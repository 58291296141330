import React from 'react'
import { useModal } from '../../context/ModalContext'
import { Button } from '@material-ui/core'

const CustomModal = ({ children, buttonText = 'View More' }) => {
  const { openModal } = useModal()

  return (
    <button
      className="button-primary px-10 mr-1"
      onClick={() => openModal(() => children)}
    >
      {buttonText}
    </button>
  )
}

export default CustomModal
